<template>
    <div id="register-page">
      <RegisterHero />
      <Rules />
      
    </div>
</template>

<script>

import RegisterHero from "../components/register-components/RegisterHero"
import Rules from "../components/register-components/Rules"

export default {
  components: {
    RegisterHero,
    Rules,
  }
}
</script>