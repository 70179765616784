<template>
  <div id="about-hero">
    <div class="flex-container-mobile">
      <img clas="mobile_img" src="../../assets/svg/svg6.svg" alt="" loading="lazy"/>
      <div class="header">
        <div class="header-title">
          <h2 class="header-title-words">ENERGY</h2>
          <div class="space">
            <h2 class="header-title-words">Always</h2>
            <h2 class="header-title-words">a</h2>
          </div>
          <h2 class="header-title-words">Challenge</h2>
        </div>
        <h2 class="header-number">7</h2>
      </div>
      <div class="text">
        <div class="shape-outside"></div>
        <div class="text-content">
          <div class="first-paragraph">
            {{ $t("about.aboutHero.text") }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex-container-desktop">
      <!-- <img class=" desktop_img" src="../../assets/svg/background_bec.svg" alt="" /> -->
      <div class="text-desktop">
        <div class="shape-outside-desktop"></div>
        <div class="text-content-desktop">
          <div class="header-title-desktop fs-large-3">
            Energy, Always a Challenge 7
          </div>
          <div class="first-paragraph-desktop fs-small-4">
            {{ $t("about.aboutHero.text") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
#about-hero {
  background-color: $white;
  height: 100vh;
}

//MOBILE

.flex-container-mobile img {
  object-fit: fill;
  width: 100%;
  height: 101vh;
}

.header {
  display: flex;
  text-align: left;
  position: absolute;
  z-index: 3;
  top: 10vh;
  left: 2vh;
}

.header-title {
  margin: auto 5px auto 10px;
}

.flex-container-mobile .header-title-words {
  font-family: "Hind Siliguri";
  font-style: normal;
  font-weight: 900;
  color: $black;
  font-size: 2em;
  margin: -0.32em 0;
}

.space {
  display: flex;
  justify-content: space-between;
}

.flex-container-mobile .header-number {
  color: $yellow;
  font-family: "Hind Siliguri";
  font-style: normal;
  font-weight: 600;
  font-size: 128px;
}

.text {
  font-family: "PT Sans Caption";
  font-style: normal;
  font-size: 1.2em;
  color: $white;
  // height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.shape-outside {
  clip-path: polygon(0 0, 100% 0, 5% 88%, 10% 100%, 0 100%);
  shape-outside: polygon(0 0, 100% 0, 5% 88%, 10% 100%, 0 100%);
  width: 90vw;
  height: 100vh;
  float: left;
}

.text-content {
  position: relative;
  width: 130%;
  top: 40vh;
  left: -35vw;
}

.first-paragraph,
.second-paragraph {
  text-align: left;
}

.first-paragraph {
  margin-bottom: 15px;
}

@media screen and (min-height: 800px) and (max-width: 376px) {
  //Iphone X
  .header {
    top: 20vw;
    left: 4vw;
  }
  .flex-container-mobile .header-title-words {
    font-size: 1.9em;
  }
  .flex-container-mobile .header-number {
    font-size: 7.5em;
  }
}

@media screen and (max-height: 750px) and (max-width: 370px) {
  //S9
  .flex-container-mobile .header-title-words {
    font-size: 1.8em;
  }
  .flex-container-mobile .header-number {
    font-size: 7.5em;
  }
  .text {
    font-size: 1.1em;
  }
}

@media screen and (max-height: 700px) and (max-width: 390px) {
  //Iphone 6/7/8
  .header {
    top: 20vw;
    left: 4vw;
  }
  .flex-container-mobile .header-title-words {
    font-size: 1.8em;
  }
  .flex-container-mobile .header-number {
    font-size: 7em;
  }

  .text {
    font-size: 1.1em;
  }
}

@media screen and (max-height: 640px) and (max-width: 360px) {
  //S5
  .flex-container-mobile .header-title-words {
    font-size: 1.7em;
  }
  .flex-container-mobile .header-number {
    font-size: 6.8em;
  }
}

@media screen and (max-height: 600px) and (max-width: 350px) {
  //Iphone 5
  .header {
    top: 20vw;
    left: 4vw;
  }
  .flex-container-mobile .header-title-words {
    font-size: 1.5em;
  }
  .flex-container-mobile .header-number {
    font-size: 6em;
  }

  .text {
    font-size: 1em;
  }
}

@media screen and (max-width: 280px) {
  //Galaxy Fold
  .flex-container-mobile .header-title-words {
    font-size: 1.4em;
  }
  .flex-container-mobile .header-number {
    font-size: 6em;
  }

  .text {
    font-size: 1em;
  }
}

//DESKTOP

@media screen and (max-width: $min_desktop_width)
{ 
.flex-container-desktop {
  display: none;
}
}
@media screen and (min-width: $min_desktop_width) {
  #about-hero {
    max-height: 56.25vw;
    height: 100vh;
  }

  .flex-container-mobile {
    display: none;
  }
 
  .flex-container-desktop {
    background-image: url("../../assets/svg/background_bec_1.svg");
    background-size: cover;
    transform: scale(1.01);
     height: 100vh;
    max-height: 56.25vw;
    display: flex;

  }

  // .flex-container-desktop img {
  //   object-fit: cover;
  //   width: 100%;
  //   height: 100vh;
  //   max-height: 56.25vw;
  //   transform: scale(1.01);
  // }

  .text-desktop {
    font-family: "PT Sans Caption";
    font-style: normal;
    color: $white;
    // position: absolute;
    // top: 0;
    // left: 0;
  }

  .shape-outside-desktop {
    clip-path: polygon(0 0, 100% 0, 20% 88%, 10% 100%, 0 100%);
    shape-outside: polygon(0 0, 100% 0, 20% 88%, 10% 100%, 0 100%);
    width: 60vw;
    height: 100vh;
    float: left;
    position: relative;
    max-height: 56.25vw;
  }

  .text-content-desktop {
    position: relative;
    // position: absolute;
    width: 110%;
    top: 18vw;
    // bottom: 0;
    left: -15vw;
    @media screen and (max-width: 1200px) {
      top: 13vw;
      left: -10vw;
      width: 100%;
    }
  }

  .header-title-desktop {
    font-family: "Hind Siliguri";
    font-style: normal;
    font-weight: 600;
    margin-bottom: 120px;
    @media screen and (max-width: 1200px) {
      margin-bottom: 2vw;
    }
  }

  .first-paragraph-desktop {
    text-align: left;
  }
}

</style>
