var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"header"}},[_c('img',{class:{ logo: true, 'logo-blur': _vm.$store.state.mobileMenuIsOpen, 'is-mobile': true },attrs:{"src":require("../../assets/images/logo-EAC6.png"),"alt":"","loading":"lazy"},on:{"click":function($event){return _vm.changePage('/')}}}),_c('div',{class:{
      'burger-menu': true,
      'burger-menu-open': _vm.$store.state.mobileMenuIsOpen,
      'burger-devs': _vm.$router.currentRoute.path == '/devs',
      'is-mobile': true
    },on:{"click":function($event){return _vm.openCloseMenu()}}},[_c('div'),_c('div'),_c('div')]),_c('div',{class:{
      'mobile-header': true,
      'mobile-header-open': _vm.$store.state.mobileMenuIsOpen,
      'is-mobile': true
    }},[_vm._l((_vm.$router.options.routes.slice(0, 5)),function(page,index){return _c('div',{key:index,staticClass:"page"},[_c('div',{class:{
          'page-name': true,
          'page-name-current': page.path == _vm.$router.currentRoute.path
        },on:{"click":function($event){return _vm.changePage(page.path)}}},[_vm._v(" "+_vm._s(_vm.$t(("global.navBar.pages[" + index + "]")))+" ")])])}),_c('div',{class:{ lang: true, 'lang-open': _vm.$store.state.mobileMenuIsOpen },on:{"click":function($event){return _vm.changeLang()}}},[_c('div',{class:{ 'is-selected': _vm.$i18n.locale == 'en' }},[_vm._v("EN")]),_vm._v(" | "),_c('div',{class:{ 'is-selected': _vm.$i18n.locale == 'ro' }},[_vm._v("RO")])])],2),_c('div',{staticClass:"desktop-header"},[_vm._l((_vm.$router.options.routes.slice(0, 5)),function(page,index){return _c('div',{key:index,class:{
        page: true,
        'fs-medium-1': true
      }},[_c('div',{class:{
          'page-name-desktop': true,
          'page-name-desktop-current': page.path == _vm.$router.currentRoute.path,
          'page-name-desktop-trainings': page.path == '/' && (_vm.$router.currentRoute.path == '/trainings' || _vm.$router.currentRoute.path == '/' || _vm.$router.currentRoute.path == '/devs')
        },on:{"click":function($event){return _vm.changePageDesktop(page.path)}}},[_vm._v(" "+_vm._s(_vm.$t(("global.navBar.pages[" + index + "]")))+" ")])])}),_c('div',{staticClass:"lang-container fs-small-3",on:{"click":function($event){return _vm.changeLang()}}},[_c('div',{class:{ 'is-selected': _vm.$i18n.locale == 'ro' }},[_vm._v("RO")]),_c('div',{class:{ 'is-selected': _vm.$i18n.locale == 'en' }},[_vm._v("EN")])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }