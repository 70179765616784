const organisers = [
  {
    photo: "https://i.imgur.com/CxG5vyE.jpg"
  },
  {
    photo: "https://i.imgur.com/ZKDmnHi.jpg"
  },
  {
    photo: "https://i.imgur.com/tkV9j3g.jpg"
  },
  {
    photo: "https://i.imgur.com/eOfUAdB.jpg"
  },
  {
    photo: "https://i.imgur.com/Fqnu9Ac.jpg"
  },
  {
    photo: "https://i.imgur.com/O8vM3v1.jpg"
  }
];

export default organisers;
