<template>
  <div id="rules">
    <div class="rules-header fs-small-3">
      <h1 class="title fs-large-1">{{$t('register.title')}}</h1>
      <div class="line"></div>
    </div>
    <div class="rules-body">
      <div
        class="rules-content fs-small-2"
        v-for="(rule, index) in $t('register.rules')"
        :key="index"
        @click="expandRule(rule, index)"
      >
        <div class="flex-container">
          <img
            loading="lazy"
            class="hexagone"
            src="../../assets/svg/hexa.svg"
          />
          <p class="index">{{ index + 1 }}</p>
          <p class="rule-title fs-small-3">{{ rule.title }}</p>
        </div>
        <v-expand-transition>
          <div v-show="rule.expand">
            <div
              class="rule-text fs-small-2"
              v-for="(text, index2) in rule.text"
              :key="index2"
              v-html="text"
            ></div>
          </div>
        </v-expand-transition>
        <div class="rule-line"></div>
      </div>
    </div>
    <div class="rules-footer"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    expandRule(rule, index) {
      rule.expand = !rule.expand;
      // get all the elemnts containing the class 'rule-line' and select the correct one
      // in order to add the class 'move' for the transition to trigger
      let elem = document.querySelectorAll(".rule-line")[index];
      let elem2 = document.querySelectorAll(".rule-title")[index];
      if (elem.classList.contains("move")) {
        elem.classList.remove("move");
        elem2.classList.remove("move-title");
      } else {
        elem.classList.add("move");
        elem2.classList.add("move-title");
      }
    },
  },
};
</script>

<style scoped lang="scss">
// mobile
#rules {
  position: relative;
  // padding-bottom: 20px;
}
.rules-header {
  height: 180px;
  background: url("../../assets/svg/svg4.svg") no-repeat;
  background-size: 100%;
}
.rules-footer {
  height: 150px;
  background: url("../../assets/svg/svg5.svg") no-repeat;
  background-size: cover;
  z-index: 4;
  position: relative;
@media (min-width: 400px) {
  height: 155px;
}

}

.title {
  position: absolute;
  font-size: $font_mobile_large_2;
  top: 1.5em;
  left: 1em;
  font-family: "Hind Siliguri";
  color: $white;
}
// yellow line under the title
.line {
  position: absolute;
  top: 5.55em;
  left: 2em;
  border-top: 2px solid #a47d13;
  width: 5em;
}

// hexagones and rule's content
.flex-container {
  display: flex;
  margin-left: 30px;
}
.rules-content {
  p {
    font-size: $font_mobile_small_3;
    font-family: "PT Sans Caption";
    text-align: left;
    margin: auto;
    width: 60%;
    margin-top: 0.4em;
    margin-bottom: 0;
    color: $black;
  }
  margin-bottom: 50px;
  font-family: "PT Sans Caption";
}
.hexagone {
  width: 4em;
  height: 4em;
}

.index {
  position: absolute;
  margin-top: 0.3em !important;
  left: 2.25em;
  font-size: $font_mobile_medium_2 !important;
  color: $blue !important;
}

// 10 was a bit too on the left
.rules-content:nth-last-child(1) {
  .index {
    left: 1.9em;
  }
}

//some rules title's werent centered vertically
.rules-content:nth-of-type(5),
.rules-content:nth-of-type(6),
.rules-content:nth-of-type(7),
.rules-content:nth-of-type(9),
.rules-content:nth-of-type(11) {
  p + p {
    margin-top: 0.9em;
  }
}

//underline of every rule
.rule-line {
  border-top: 2px solid $blue;
  position: relative;
  left: 6.5em;
  width: 20em;
  max-width: 240px;
  transition: left 0.25s ease-out;
  margin-bottom: 0.5em;
}
// used for transition of rule-line
.move {
  left: 3em;
}

// expand animation
.rule-text {
  position: relative;
  font-size: $font_mobile_small_1;
  margin: auto;
  margin-bottom: 1em;
  width: 80%;
  left: 4%;
}

@media (min-width: 480px) {
  #rules {
  padding-bottom: 20px;
}
  .rules-footer {
    background: url("../../assets/svg/rules-desktop-svg.svg") no-repeat;
    background-size: 100%;
    height: 22.5vw;
    width: 100%;
    position: absolute;
    bottom: -8vw;
  }
  .rules-header {
    height: 30vw;
    background: url("../../assets/svg/svg7.svg") no-repeat;
    background-size: 100%;
  }
  .rules-content {
    position: relative;
    max-width: 1440px;
    margin: auto;
  }
  .flex-container {
    display: flex;
    margin-left: 30px;
  }
  .rule-line {
    width: 80%;
    max-width: none;
  }
  .rules-content {
    p + p {
      // margin-left: 2%;
      // width: auto;
      // max-width: 800px;
    }
    margin-bottom: 50px;
  }

  .rules-body {
    position: relative;
    bottom: 10vw;
  }

  .index {
    max-width: 100px;
    font-size: 1.4em !important;
    @media screen and (min-width: 480px) {
      top: 0.1em;
      left: 2.6em;
    }
    @media screen and (min-width: 1280px) {
      top: 0.1em;
      left: 2.1em;
    }
    @media screen and (min-width: 1921px) {
      left: 1.85em;
    }
  }

  // 10 was a bit too on the left
  .rules-content:nth-last-child(1) {
    .index {
      @media screen and (min-width: 480px) {
        left: 2.3em;
      }
      @media screen and (min-width: 1280px) {
        left: 1.8em;
      }
      @media screen and (min-width: 1921px) {
        left: 1.5em;
      }
    }
  }
  // animation for text
  .rule-title {
    transition: width 300ms ease-out;
    cursor: pointer;
    width: 100%;
    text-align: center !important;
    margin: 0;
  }
  .move-title {
    width: 100%;
  }
  //animation for bar updated
  .move {
    @media screen and (min-width: 480px) {
      left: 5%;
    }
    @media screen and (min-width: 1280px) {
      left: 4%;
    }
    @media screen and (min-width: 1921px) {
      left: 3%;
    }
  }
}
</style>
