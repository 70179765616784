const contacts = [
  {
    photo: "https://i.imgur.com/Dl6iGAX.jpg"
  },
  {
    photo: "https://i.imgur.com/CxG5vyE.jpg"
  }
];

export default contacts;
