<template>
  <div id="home-hero">
    <div class="body">
      <div class="text">
        <p>ENERGY</p>
        <pre>Always   a</pre>
        <p>Challenge</p>
      </div>
      <div class="number">7</div>
      <div class="slider">
        <div class="white-bar"></div>
        <div class="slider-track">
          <div
            class="slide"
            v-for="(image, index) in homeHero.images"
            :key="index"
          >
            <img loading="lazy" class="img_mobile" :src="image" />
          </div>
        </div>
      </div>
    </div>
    <div class="images">
      <div class="img-desktop">
        <img loading="lazy" class="img" :src="image_1" />
      </div>
      <div class="img-desktop">
        <img loading="lazy" class="img" :src="image_2" />
      </div>
    </div>
    <div class="widgets">
      <ApplyButton class="apply-button" />
      <Countdown extdate="2023-05-15T09:00:00Z" class="countdown" />
      <div class="text-desktop fs-large-5">
        <p>ENERGY, Always a<br />Challenge</p>
        <div class="number-desktop fs-large-6">7</div>
      </div>
    </div>
  </div>
</template>

<script>
import homeHero from "@/assets/resources/homeHero";
import ApplyButton from "@/components/widgets/ApplyButton";
import Countdown from "@/components/widgets/Countdown";
export default {
  data() {
    return {
      homeHero,
      images_desktop: [],
      images_desktop_2: [],
      image_1: "https://i.imgur.com/HbI4kH9.png",
      image_2: "https://i.imgur.com/VaLlEA7.png",
    };
  },
  components: {
    ApplyButton,
    Countdown,
  },
  created() {
    let self = this;
    this.images_desktop = [
      {
        image: "https://i.imgur.com/HbI4kH9.png",
      },
      {
        image: "https://i.imgur.com/SvRLLfk.png",
      },
      {
        image: "https://i.imgur.com/1nHPSax.png",
      },
    ];
    this.images_desktop_2 = [
      {
        image: "https://i.imgur.com/VaLlEA7.png",
      },
      {
        image: "https://i.imgur.com/HxfkYEb.png",
      },
      {
        image: "https://i.imgur.com/CfPel9m.png",
      },
    ];
    setInterval(function () {
      self.image_1 =
        self.images_desktop[
          Math.floor(Math.random() * self.images_desktop.length)
        ].image;
      self.image_2 =
        self.images_desktop_2[
          Math.floor(Math.random() * self.images_desktop_2.length)
        ].image;
    }, 1500);
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 480px) {
  .text-desktop {
    display: none;
  }
  .images {
    display: none;
  }
}
@media screen and (min-width: 480px) {
  .images {
    width: 90vw;
    height: 90vh;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 25%;
  }
  .img-desktop {
    height: 90%;
    width: 30vw;
    max-width: 650px;
    overflow: hidden;
  }
  .img {
    height: 95% !important;
  }
  .text {
    display: none;
  }
  .number {
    display: none;
  }
  .text-desktop {
    font-family: "Hind Siliguri";
    font-weight: 700;
    color: $white;
    display: flex;
    justify-content: center;
  }
  p {
    text-align: right;
    line-height: 1.1;
  }
  .number-desktop {
    line-height: 0.6;
    margin: 0;
    color: $yellow;
  }
  .img_mobile {
    display: none;
  }
  #home-hero {
    background: $blue !important;
    // max-height: 56.25vw;
  }
  .widgets {
    display: flex;
    flex-direction: column-reverse !important;
    align-items: center !important;
    row-gap: 30% !important;
    margin-bottom: 3em;
    height: 70vh;
  }
  .apply-button {
    transform: scale(1.2) translateY(-50px) !important;
    color: $white !important;
    padding-top: 1em;
  }

  .countdown {
    transform: scale(1.8) !important;
  }
  .white-bar {
    display: none;
  }
  .slider-track {
    display: none;
  }
  .slide {
    display: none;
  }
}

#home-hero {
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(90deg, $white 50%, $blue 50%);
  position: relative;
}
.white-bar {
  width: 10%;
  height: 100%;
  background-color: $white;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}
.text {
  margin-left: 0.8em;
  color: $black;
  font-family: "Hind Siliguri";
  font-size: 32px;
  text-align: left;
  font-weight: 700;
}
.number {
  font-family: "Hind Siliguri";
  font-style: normal;
  font-weight: 600;
  color: $yellow;
  font-size: 128px;
  position: absolute;
  left: 1.5em;
  top: 0.43em;
}
p,
pre {
  margin-bottom: -20px;
  font-family: "Hind Siliguri";
}
.widgets {
  position: absolute;
  width: 100vw;
  bottom: 3em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.apply-button {
  transform: scale(0.6);
  color: black;
}

.countdown {
  transform: scale(1);
  text-shadow: 2px 2px $blue;
}
.body {
  display: flex;
  flex-direction: column;
  row-gap: 3em;
  padding-top: 6em;
}

.slider {
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
}
.slider-track {
  display: flex;
  position: relative;
  width: calc(200px * 6);
  animation: scroll 18s ease-out infinite;
  margin-left: 2em;
}
.slide {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
}
.img_mobile {
  width: 100%;
}

@keyframes scroll {
  0% {
    right: 0px;
  }
  5% {
    right: 0px;
  }
  10% {
    right: 200px;
  }
  15% {
    right: 200px;
  }
  20% {
    right: 400px;
  }
  25% {
    right: 400px;
  }
  30% {
    right: 600px;
  }
  35% {
    right: 600px;
  }
  40% {
    right: 800px;
  }
  45% {
    right: 800px;
  }
  50% {
    right: 600px;
  }
  55% {
    right: 600px;
  }
  60% {
    right: 400px;
  }
  65% {
    right: 400px;
  }
  70% {
    right: 200px;
  }
  75% {
    right: 200px;
  }
  80% {
    right: 0px;
  }
  85% {
    right: 0px;
  }
}

// @media screen and (min-height: 800px) {
//   .widgets {
//     bottom: 5em;
//   }
//   .apply-button {
//     transform: scale(1)!important;
//   }
//   .body {
//     row-gap: 5em;
//   }
// }

@media screen and (max-height: 720px) {
  .white-bar {
    width: 10%;
    height: 18.2em;
    bottom: -8.1em;
  }
  .body {
    row-gap: 10em;
  }
  .slide {
    height: 0px;
    padding: 15px;
  }
  .slider-track {
    width: calc(180px * 6);
  }
  @keyframes scroll {
    0% {
      right: 0px;
    }
    5% {
      right: 0px;
    }
    10% {
      right: 180px;
    }
    15% {
      right: 180px;
    }
    20% {
      right: 360px;
    }
    25% {
      right: 360px;
    }
    30% {
      right: 540px;
    }
    35% {
      right: 540px;
    }
    40% {
      right: 720px;
    }
    45% {
      right: 720px;
    }
    50% {
      right: 540px;
    }
    55% {
      right: 540px;
    }
    60% {
      right: 360px;
    }
    65% {
      right: 360px;
    }
    70% {
      right: 180px;
    }
    75% {
      right: 180px;
    }
    80% {
      right: 0px;
    }
    85% {
      right: 0px;
    }
  }
  // .apply-button {
  //   transform: scale(0.7);
  // }

  .countdown {
    transform: scale(0.8);
  }
}

@media screen and (max-height: 570px) {
  .white-bar {
    width: 10%;
    height: 15em;
    bottom: -6.2em;
  }
  .text {
    font-size: 28px;
  }
  .number {
    font-size: 115px;
  }
  .widgets {
    bottom: 1em;
  }
  img {
    height: 14em;
  }
  .body {
    row-gap: 9em;
  }
  .slide {
    height: 0px;
    padding: 15px;
  }
  .slider-track {
    width: calc(160px * 6);
  }
  @keyframes scroll {
    0% {
      right: 0px;
    }
    5% {
      right: 0px;
    }
    10% {
      right: 160px;
    }
    15% {
      right: 160px;
    }
    20% {
      right: 320px;
    }
    25% {
      right: 320px;
    }
    30% {
      right: 480px;
    }
    35% {
      right: 480px;
    }
    40% {
      right: 640px;
    }
    45% {
      right: 640px;
    }
    50% {
      right: 480px;
    }
    55% {
      right: 480px;
    }
    60% {
      right: 320px;
    }
    65% {
      right: 320px;
    }
    70% {
      right: 160px;
    }
    75% {
      right: 160px;
    }
    80% {
      right: 0px;
    }
    85% {
      right: 0px;
    }
  }
  .apply-button {
    transform: scale(0.5) !important;
  }

  .countdown {
    transform: scale(0.8);
  }
}
</style>
