<template>
  <div id="organisers">
    <div class="title fs-medium-1">
      <h1>{{ $t("about.organisers.title") }}</h1>
    </div>
    
    <div class="svg-container">
      <div class="yellow-bar-skew"></div>
      <div class="yellow-bar"></div>
      <div class="cyan-bar"></div>
      <div class="triangle-bar"></div>
    </div>
    <div class="background">
      
        <div
          class="card"
          v-for="(organiser, index) in organisers"
          :key="index"
        >
          <div class="image-box">
            <img loading="lazy" :src="organiser.photo" />
          </div>
          <div>
            <h2 class="fs-medium-1">{{ $t(`about.organisers.organisers[${index}].title`) }}</h2>
            <p class="fs-small-2">{{ $t(`about.organisers.names[${index}].name`) }}</p>
          </div>
        </div>
      
    </div>
    <div class="desktop-svg-container">
      <div class="svg-triangle-cyan"></div>
      <div class="svg-triangle-white"></div>
      <div class="svg-triangle-white-right"></div>
      <div class="svg-triangle-cyan-right"></div>
    </div>
  </div>
</template>

<script>
import organisers from "@/assets/resources/organisers";
export default {
  data() {
    return {
      organisers,
    };
  },
};
</script>

<style scoped>
</style>

<style scoped lang="scss">
#organisers {
  background-color: $white;
}

.desktop-svg-container {
  position: relative;
}
.svg-triangle-white-right {
  width: 0;
  height: 0;
  border-bottom: 20em solid $white;
  border-left: 20em solid transparent;
  position: absolute;
  transform: rotate(135deg);
  margin-top: -60em;
  right: -15em;
}

.svg-triangle-cyan-right {
  width: 10em;
  height: 3em;
  background-color: $turquoise;
  position: absolute;
  transform: rotate(-45deg);
  margin-top: -62em;
  right: -6em;
}

.svg-triangle-white {
  width: 0;
  height: 0;
  border-bottom: 8em solid $white;
  border-left: 5em solid transparent;
  position: absolute;
  transform: rotate(-50deg);
  margin-top: -10em;
  margin-left: -4em;
  
}

.svg-triangle-cyan {
  width: 0;
  height: 0;
  border-bottom: 13em solid $turquoise;
  border-left: 13em solid transparent;
  position: absolute;
  transform: rotate(-31deg);
  margin-top: -10em;
  margin-left: -9em;
}

.svg-container {
  margin-top: 5em;
  position: relative;
}


h1 {
  text-align: left;
  margin-left: 1em;
  font-family: "Hind Siliguri";
  font-weight: 700;
  position: relative;
  top: 0.7em;
  color:$black;
}

h2 {
  color: $white;
  font-family: "PT Sans Caption";
  font-weight: 700;
  font-size: 2em;
  margin-top: 60px;
 
}

p {
  color: $white;
  font-family: "PT Sans Caption";
  font-weight: 500;
  font-size: 1.5em;
  
}
.card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 8em;
  flex-basis: 30%;
}



.image-box {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 110%;
    min-width: 165px;
    top: -5%;
    left: 0;
    box-sizing: border-box;
    border: 7px solid $turquoise;
    transform: skew(-7deg);
    z-index: 2;
  }
}

img {
  width:20em;
  height: 100%;
  border: 0.5em solid $yellow;
  object-fit: cover;
}

.background {
  background-color: $blue;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.cyan-bar {
  height: 3em;
  background-color: $turquoise;
}

.triangle-bar {
  width: 0;
  height: 0;
  border-bottom: 10em solid $turquoise;
  border-left: 10em solid transparent;
  position: absolute;
  bottom: 1.8em;
  right: 0;
}

.yellow-bar-skew {
  height: 4em;
  width: 0.7em;
  background-color: $yellow;
  position: absolute;
  bottom: 3em;
  right: 6.8em;
  transform: skew(-45deg);
}

.yellow-bar {
  height: 0.4em;
  background-color: $yellow;
  position: absolute;
  bottom: 3em;
  right: 9em;
  // margin-top: 16em;
  width: 12em;
}

@media screen and (max-width: 480px) {
  .title{
  width: 3em;
}
  h1{
    top:2em;
  }

  .card {
    width: 100%;
  }

  .svg-triangle-white-right {
    width: 0;
    height: 0;
    border-bottom: 8em solid transparent;
    border-left: 5em solid transparent;
  }

  .svg-triangle-cyan-right {
    width: 0;
    height: 0;
    border-bottom: 8em solid transparent;
    border-left: 5em solid transparent;
    margin-right: -100em;
  }

  .svg-triangle-white {
    width: 0;
    height: 0;
    border-bottom: 8em solid transparent;
    border-left: 5em solid transparent;
  }

  .svg-triangle-cyan {
    width: 0;
    height: 0;
    border-bottom: 8em solid transparent;
    border-left: 5em solid transparent;
  }

  .cyan-bar {
  height: 30px;
  background-color: $turquoise;
}

.triangle-bar {
  width: 0;
  height: 0;
  border-bottom: 80px solid $turquoise;
  border-left: 80px solid transparent;
  position: absolute;
  bottom: 1.8em;
  right: 0;
}

.yellow-bar-skew {
  height: 2.9em;
  width: 0.7em;
  background-color: $yellow;
  position: absolute;
  bottom: 30px;
  right: 3.4em;
  transform: skew(-45deg);
}

.yellow-bar {
  height: 0.3em;
  background-color: $yellow;
  position: absolute;
  bottom: 30px;
  right: 3.4em;
  // margin-top: 16em;
  width: 10em;
}

img {
  width:10em;
  height: 100%;
  border: 0.25em solid $yellow;
}

.image-box {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 110%;
    min-width: 165px;
    top: -5%;
    left: 0;
    box-sizing: border-box;
    border: 4px solid $turquoise;
    transform: skew(-7deg);
    z-index: 2;
  }
}

.card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3em;
  flex-basis: 30%;
}
}
</style>
