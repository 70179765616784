<template>
  <div id="home-card-grid">
    <img loading="lazy" class="liniute" src="@/assets/images/svg1.svg" />
    <h1 class="fs-large-2">{{ $t("home.homeCardGrid.header") }}</h1>
    <div class="container">
      <p>{{ $t("home.homeCardGrid.description1") }}</p>
      <img loading="lazy" src="https://i.imgur.com/ZtyPhPo.png" />
      <img loading="lazy" src="https://i.imgur.com/LTSA3W0.png" />
      <p>{{ $t("home.homeCardGrid.description2") }}</p>
      <p>{{ $t("home.homeCardGrid.description3") }}</p>
      <img loading="lazy" src="https://i.imgur.com/GZQ5gFu.png" />
      <p>{{ $t("home.homeCardGrid.description4") }}</p>
      <p>{{ $t("home.homeCardGrid.description5") }}</p>
    </div>
    <img loading="lazy" class="last-image" src="https://i.imgur.com/lQjNP7y.png" />
    <div class="container-desktop">
      <div class="image">
        <img loading="lazy" class="image-img" src="https://i.imgur.com/sDqpj8y.png" />
        <div class="image-overlay">
          <p class="image-description fs-small-3">
            {{ $t("home.homeCardGrid.description1") }}
          </p>
        </div>
      </div>
      <div class="image">
        <img loading="lazy" class="image-img" src="https://i.imgur.com/i2Gorva.png" />
        <div class="image-overlay">
          <p class="image-description fs-small-3">
            {{ $t("home.homeCardGrid.description2") }}
          </p>
        </div>
      </div>
      <div class="image">
        <img loading="lazy" class="image-img" src="https://i.imgur.com/6XFgS6M.png" />
        <div class="image-overlay">
          <p class="image-description fs-small-3">
            {{ $t("home.homeCardGrid.description3") }}
          </p>
        </div>
      </div>
      <div class="image">
        <img loading="lazy" class="image-img" src="https://i.imgur.com/Cm5bXzA.png" />
        <div class="image-overlay">
          <p class="image-description fs-small-3">
            {{ $t("home.homeCardGrid.description4") }}
          </p>
        </div>
      </div>
      <div class="image">
        <img loading="lazy" class="image-img" src="https://i.imgur.com/AJOzCwP.png" />
        <div class="image-overlay">
          <p class="image-description fs-small-3">
            {{ $t("home.homeCardGrid.description5") }}
          </p>
        </div>
      </div>
    </div>
    <div class = "line-footer-desktop">
      <div class="line-large"></div>
      <div class="line-small"></div>
    </div>
    <img loading="lazy" class="liniute1" src="@/assets/images/svg2.svg" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
//desktop
@media screen and (min-width: 480px) {
  .liniute {
    display: none;
  }
  .container {
    display: none !important;
  }
  .last-image {
    display: none !important;
  }
  #home-card-grid {
    padding-top: 4em;
    padding-left: 4em;
    padding-right: 4em;
    padding-bottom: 10em !important;
    position: relative;
  }
  .container-desktop {
    width: 60vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    row-gap: 1.5em;
    column-gap: 1.5em;
  }
  .image {
    position: relative;
    width: 40%;
    height: 40%;
    align-items: center;
    min-width: 300px;
    max-width: 500px;
  }
  .image-img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    color: $white;
    display: flex;
    height: 100%;
    opacity: 0;
    transition: opacity 250ms ease;
  }
  .image-overlay > * {
    transform: translateY(20px);
    transition: transform 250ms ease;
  }
  .image-overlay:hover {
    opacity: 1;
  }
  .image-overlay:hover > * {
    transform: translateY(-10px);
  }
  .image-description {
    width: 80%;
    height: 20%;
    align-items: center;
    font-family: "PT Sans Caption";
  }
  .liniute1 {
    display: none;
  }
  .line-footer-desktop{
    width: 100% ;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    row-gap: 2em;
  }
  .line-large{
    width: 60%;
    height: 1.5em;
    background-color: $turquoise;
  }
  .line-small{
    width: 30%;
    height: 1.5em;
    background-color: $turquoise;
  }
}
@media screen and (max-width: 480px) {
  .container-desktop {
    display: none;
  } 
  .line-footer-desktop{
    display: none;
  }
}
#home-card-grid {
  background-color: $white;
  padding-bottom: 6em;
}

.liniute {
  height: 1.5em;
  width: 50%;
  margin-left: 50%;
}

.liniute1 {
  margin-top: 30px;
  height: 1.5em;
  width: 75%;
  margin-right: 25%;
}

h1 {
  font-size: $font_mobile_large_2;
  font-family: "Hind Siliguri";
  color: $black;
  margin-left: 1em;
  display: block;
  position: relative;
  text-align: left;
  margin-bottom: 3em;
  // padding-top: 1em;
  // margin-top: 1em;
  &::after {
    content: "";
    left: 0;
    background-color: $purple;
    height: 0.05em;
    position: absolute;
    width: 3.2em;
    top: 1.2em;
  }
}

.container {
  width: 90%;
  display: grid;
  grid-template-columns: 48% 48%;
  justify-items: center;
  grid-gap: 4%;
  margin: auto;
}

p {
  font-family: "MS-UI-Gothic";
  font-size: $font_mobile_medium_1;
  line-height: 1.2;
  margin: auto;
  display: inline-block;
  height: 216px;
  width: 100%;
  @media screen and (max-width: 350px) {
 font-size: $font_mobile_small_3;
}
}

.container p:nth-of-type(1) {
  background-color: $purple;
  color: $white;
  padding: 1.7em 1em;
}

.container p:nth-of-type(2),
p:nth-of-type(4) {
  background-color: $turquoise;
  color: $white;
}

.container p:nth-of-type(3),
p:nth-of-type(5) {
  border: 3px solid $purple;
}

.container p:nth-of-type(2),
p:nth-of-type(3) {
  padding: 1em 1em;
}

.container p:nth-of-type(4),
p:nth-of-type(5) {
  height: 9em;
  padding: 1.2em 1em;
}

.container p:nth-of-type(2),
p:nth-of-type(3),
p:nth-of-type(4),
p:nth-of-type(5) {
  transform: translateY(-3.5em);
}

.container img:nth-of-type(3) {
  transform: translateY(-4.5em);
  height: 13.5em;
}
img {
  object-fit: cover;
  width: 100%;
  height: 9em;
}

.last-image {
  margin-top: 50px;
  width: 90%;
}
</style>
