<template>
  <div id="partners">
    <h1 class="fs-large-2">{{ $t("home.partners.header") }}</h1>
    <div v-for="(partnerType, index) in getPartners" :key="index" class="partner-type">
      <h2 class="fs-large-1" v-if="$i18n.locale == 'ro'">{{ partnerType.nameRo }}</h2>
      <h2 class="fs-large-1" v-else>{{ partnerType.nameEn }}</h2>
      <div v-for="(partner, index2) in partnerType.content" :key="index2" class="partner-container">
        <a :href="partner.link" class="partner">
          <img loading="lazy" :src="partner.logo" :alt="partner.name" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters("partnersModule", ["getPartners", "getLoadingPartners"])
  },
  methods: {
    ...mapActions("partnersModule", ["requestPartners"])
  },
  mounted() {
    this.requestPartners();
  }
};
</script>

<!-- <template>
  <div id="partners">
    <h1 class="fs-large-2">{{ $t("home.partners.header") }}</h1>
    <div v-for="(partnerType, index) in getPartners" :key="index" class="partner-type">
      <h2 class="fs-large-1" v-if="$i18n.locale == 'ro'">{{ partnerType.nameRo }}</h2>
      <h2 class="fs-large-1" v-else>{{ partnerType.nameEn }}</h2>
      <div v-for="(partner, index2) in partnerType.content" :key="index2" class="partner-container">
        <a :href="partner.link" class="partner">
          <img loading="lazy" :src="partner.logo" :alt="partner.name" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      partners: [
        {
          content: [
            {
              logo: "https://i.imgur.com/hYY068k.png"
            }
          ]
        },
        {
          content: [
            {
              logo: "https://i.imgur.com/LZ8p1Ae.png"
            }
          ]
        }
        // Add more partners as needed
      ]
    };
  },
  computed: {
    getPartners() {
      return this.partners;
    },
    getLoadingPartners() {
      return false;
    }
  }
};
</script> -->

<style scoped lang="scss">
#partners {
  background-color: $white;
  height: auto;
  // margin-top: 93.43px;
}

h1 {
  font-size: $font_mobile_large_2;
  font-family: "Hind Siliguri";
  font-weight: bold;
  color: $black;
  text-align: left;
  position: relative;
  margin-left: 1em;
  margin-bottom: 2em;
  &::after {
    content: "";
    background-color: $purple;
    position: absolute;
    bottom: 10px;
    left: 0em;
    width: 22.5%;
    height: 1px;
  }
}

h2 {
  font-size: $font_mobile_medium_2;
  font-family: "PT Sans Caption";
  font-weight: 400;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 10px;
  color: $black;
  display: inline-block;
  position: absolute;
  border-bottom: 1px solid $turquoise;
}

.partner > img {
  opacity: 1;
  max-width: 35vw;
  margin: 0px 0;
  max-height: 10vh;
  min-width: 10vw;
}

.partner-type {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.partner-container {
  margin-top: 60px;
  margin-right: 10px;
  margin-left: 10px;
  align-items: center;
}

@media screen and (min-width: 480px) {
  #partners {
    padding-top: 5em;
    padding-left: 5em;
    padding-right: 5em;
    padding-bottom: 5em;
  }
  h1 {
    font-size: 50px;
    font-family: "Hind Siliguri";
    font-weight: bold;
    color: $black;
    text-align: left;
    position: relative;
    &::after {
      content: "";
      background-color: $purple;
      position: absolute;
      bottom: 10px;
      left: 0em;
      width: 8.5%;
      height: 1px;
    }
  }

  h2 {
    font-size: 48px;
    font-family: "PT Sans Caption";
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 10px;
    color: $black;
    display: inline-block;
    position: absolute;
    &::after {
      content: "";
      background-color: $turquoise;
      position: absolute;
      bottom: 1px;
      right: 50px;
      width: 90%;
      height: 1px;
      left: -15%;
    }
    border-bottom: 0px;
  }

  .partner-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    justify-content: center;
    margin: auto;
    margin-bottom: 100px;
    width: 80%;
  }

  .partner-container {
    margin-top: 120px;
    margin-right: 10px;
    margin-left: 10px;
    align-items: center;
  }
  .partner > img {
    filter: grayscale(100%);
    opacity: 0.66;
    transition: all 0.3s ease;
    max-height: 10vh;
    min-width: 10vw;
    object-fit: contain;
    margin: 1vh 1vw 1vh 1vw;
    outline: none;
  }

  .partner > img:hover {
    filter: grayscale(0%);
    opacity: 1;
  }
}
</style>
