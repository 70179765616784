<template>
    <div id="about-page">
        <AboutHero />
        <PastEditions />
        <Organisers />
    </div>
</template>

<script>

import AboutHero from "../components/about-components/AboutHero"
import PastEditions from "../components/about-components/PastEditions"
import Organisers from "../components/about-components/Organisers"

export default {
  components: {
    AboutHero,
    PastEditions,
    Organisers
  }
}
</script>
