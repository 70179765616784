const partners = [
  {
    order: 0,
    nameEn: "Anual",
    nameRo: "Anuali",
    content: [
      {
        name: "R Systems",
        link: "https://www.rsystems.com/",
        logo: "https://i.imgur.com/hYY068k.png"
      },
      {
        link: "https://www.hipo.ro/",
        name: "Hipo",
        logo: "https://i.imgur.com/LZ8p1Ae.png"
      }
    ]
  },
  {
    order: 1,
    nameEn: "Academic",
    nameRo: "Academici",
    content: [
      {
        link: "https://energ.upb.ro/",
        name: "Facultatea de Energetica",
        logo: "https://i.imgur.com/wctjufF.png"
      },
      {
        name: "UPB",
        link: "https://upb.ro/",
        logo: "https://i.imgur.com/ZSslFAS.png"
      },
      {
        name: "FIIR",
        link: "https://www.fiir.upb.ro/index.php/ro/",
        logo: "https://i.imgur.com/cuijjgM.png"
      }
    ]
  },
  {
    order: 2,
    nameEn: "Financial",
    nameRo: "Financiari",
    content: [
      {
        link: "https://eagleenergygroup.ro/",
        name: "Eagle Energy Group",
        logo: "https://i.imgur.com/1tqU3Wx.png"
      },
      {
        name: "Bunge",
        link: "https://www.bunge.com/",
        logo: "https://i.imgur.com/bYtz67X.png"
      },
      {
        link: "https://www.siemens.com/ro/ro.html",
        name: "Siemens",
        logo: "https://i.imgur.com/gtkg47K.png"
      },
      {
        name: "Schneider Electric",
        link: "https://www.se.com/ro/ro/",
        logo: "https://i.imgur.com/LIwmQkl.png"
      }
    ]
  },
  {
    order: 3,
    nameEn: "Logistics",
    nameRo: "Logistici",
    content: [
      {
        link: "https://landing.bookster.ro/",
        name: "Bookster",
        logo: "https://i.imgur.com/FZmAqka.png"
      },
      {
        name: "Questo",
        link: "https://questoapp.com/",
        logo: "https://i.imgur.com/DdP3JKE.png"
      },
      {
        link: "https://www.redbull.com/ro-ro/",
        name: "Red Bull",
        logo: "https://i.imgur.com/RgPd3uI.png"
      }
    ]
  },
  {
    order: 4,
    nameEn: "Media",
    nameRo: "Media",
    content: [
      {
        link: "https://bestbucuresti.ro/",
        name: "Best",
        logo: "https://i.imgur.com/sfRWsPY.png"
      },
      {
        name: "ASFE",
        link:
          "https://asfe2k18.wixsite.com/asfe?fbclid=IwAR1ZDtLg6Ylx3_EfrCrOT0mACNqSsLvnpmK5FjlT0GeD3gKUcAyHupk5lfc",
        logo: "https://i.imgur.com/FNERgD2.png"
      },
      {
        link: "https://euroavia-bucuresti.ro/",
        name: "Euroavia",
        logo: "https://i.imgur.com/8ZzkZvm.png"
      },
      {
        name: "LSAC",
        link: "https://lsacbucuresti.ro/",
        logo: "https://i.imgur.com/N08Ixwp.png"
      },
      {
        link: "https://www.iqads.ro/",
        name: "IQads",
        logo: "https://i.imgur.com/gscegVS.png"
      },
      {
        link: "https://4mayo.ro/",
        name: "4Mayo",
        logo: "https://i.imgur.com/DmZ8AQS.png"
      }
    ]
  }
];
export default partners;
