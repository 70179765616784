<template>
  <div id="back-to-top-button">
    <a href="#" class="button-to-top">
      <div class="img" />
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    toTopSelector() {
      return document.querySelector(".button-to-top");
    }
  },
  methods: {
    toTopfunction() {
      const toTop = this.toTopSelector;
      const windowOffset = window.pageYOffset;
      const windowSize = window.innerHeight;
      if (windowOffset + windowSize > 1.6 * windowSize) {
        toTop.classList.add("active");
      } else {
        toTop.classList.remove("active");
      }
    }
  },
  mounted() {
    document.addEventListener("scroll", this.toTopfunction);
  }
};
</script>

<style lang="scss" scoped>
#back-to-top-button {
  z-index: 5;
  position: relative;
}

.img {
  z-index: 1;
  height: 100%;
  width: 100%;
  clip-path: polygon(50% 32%, 20% 60%, 80% 60%);
  background-color: $white;
  transition: 0.3s ease-in;

  @media (max-width: 460px) {
    height: 100%;
    width: 100%;
  }
}

a {
  background-color: $blue;
  position: fixed;
  right: 7%;
  bottom: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 0.2em $white;
  opacity: 0;
  width: 4.5em;
  height: 4.5em;
  min-width: 32px;
  min-height: 32px;
  pointer-events: none;
  transition: 0.3s;
  @media (max-width: 460px) {
    height: 2.7em;
    width: 2.7em;
  }
}
a:hover {
  @media (min-width: 480px) {
    border-radius: 50%;
  }
  & > .img {
    clip-path: polygon(64% 15%, 30% 51%, 45% 51%, 29% 81%, 65% 45%, 51% 44%);
    background-color: $yellow;
  }
}

.button-to-top.active {
  pointer-events: all;
  opacity: 1;
}
</style>
