<template>
  <div id="CaseStudy">
   
      <h1 class="fs-medium-2" v-html="$t('home.title') + '<br>'"></h1>
    
    <div class="svg-container">
      <div class="yellow-bar-skew"></div>
      <div class="yellow-bar"></div>
      <div class="cyan-bar"></div>
      <div class="triangle-bar"></div>
    </div>

    <div class="background"  @click="goToDevs()">
      <div class="text-box">
        <img loading="lazy" src="https://i.imgur.com/hycBW1D.jpg" />
        <p class="fs-small-3">{{ $t("home.aboutEestec") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nrOfClicks: 0
    }
  },
  methods: {
    goToDevs() {
      if (this.nrOfClicks === 2) {
        this.nrOfClicks = 0;
        if (this.$route.name !== "DevPage") {
          this.$router.push("/devs");
        }
      } else {
        this.nrOfClicks += 1;
      }
      console.log(this.nrOfClicks);
    },
  },
};
</script>

<style scoped lang="scss">
#CaseStudy {
  background-color: $white;
}
h1 {
  text-align: left;
  margin-left: 32px;
  font-family: "Hind Siliguri";
  font-weight: bold;
  position: relative;
  top: 1.5em;
  font-size: $font_mobile_large_2;
  color: $black;
  @media screen and (max-width: 390px) {
    font-size: $font_mobile_large_1;
    top: 2.4em;
  }
  @media screen and (max-width: 340px) {
    font-size: $font_mobile_medium_2;
    top: 2.8em;
  }
  @media screen and (min-width: 480px){
    margin-left: 2em;
    line-height: 1.4;
  }
}
img {
  width: 100%;
  height: 70%;
  object-fit: cover;
}

.text-box {
  border: 1px solid $yellow;
  background-color: $blue;
  font-family: "PT Sans Caption";
  font-size: $font_mobile_small_3;
  color: $white;
  width: 90%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 2em;
  @media screen and (max-width: 390px) {
    font-size: $font_mobile_small_2;
  }
}

.background {
  background-color: $blue;
  height: fit-content;
  display: flex;
  justify-content: space-around;
  padding-top: 3em;
  padding-bottom: 7em;
}

p {
  margin-top: 20px;
  width: 100%;
}

.cyan-bar {
  height: 30px;
  background-color: $turquoise;
}

.triangle-bar {
  width: 0;
  height: 0;
  border-bottom: 130px solid $turquoise;
  border-left: 80px solid transparent;
  position: absolute;
  bottom: 1.8em;
  right: 0;
}

.svg-container {
  margin-top: 5em;
  position: relative;
}

.yellow-bar-skew {
  height: 3em;
  width: 0.5em;
  background-color: $yellow;
  position: absolute;
  bottom: 30px;
  right: 4em;
  transform: skew(-33deg);
}

.yellow-bar {
  height: 0.3em;
  background-color: $yellow;
  position: absolute;
  bottom: 30px;
  right: 3.4em;
  margin-top: 16em;
  width: 6em;
}

@media screen and (min-width: 640px) {
  .cyan-bar {
    height: 3em;
    background-color: $turquoise;
  }

  .triangle-bar {
    width: 0;
    height: 0;
    border-bottom: 10em solid $turquoise;
    border-left: 10em solid transparent;
    position: absolute;
    bottom: 1.8em;
    right: 0;
  }

  .yellow-bar-skew {
    height: 4em;
    width: 0.7em;
    background-color: $yellow;
    position: absolute;
    bottom: 3em;
    right: 6.8em;
    transform: skew(-45deg);
  }

  .yellow-bar {
    height: 0.4em;
    background-color: $yellow;
    position: absolute;
    bottom: 3em;
    right: 9em;
    // margin-top: 16em;
    width: 12em;
  }

  img {
    width: 95%;
    height: 70%;
    object-fit: cover;
  }

  .text-box {
    width: 50%;
  }
  p {
    margin-top: 2em;
    width: 90%;
  }
}
</style>
