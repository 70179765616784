import { render, staticRenderFns } from "./Trainings.vue?vue&type=template&id=20adec84&scoped=true&"
import script from "./Trainings.vue?vue&type=script&lang=js&"
export * from "./Trainings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20adec84",
  null
  
)

export default component.exports