<template>
  <div id="register-hero">
    <img loading="lazy" src="../../assets/svg/svg61.svg" id="background-mobile" />
    <img loading="lazy" src="../../assets/svg/desktop-register.svg" id="background-desktop" />
    <div class="text-box-mobile">
      <p class="title">ENERGY</p>
      <pre class="title">Always   a</pre>
      <p class="title">Challenge</p>
    </div>
    <p id="six-mobile">7</p>
    <div class="full-title-desktop">
      <p class="title fs-large-3">
        ENERGY, Always a <br />
        Challenge
      </p>
      <p id="six-desktop">7</p>
    </div>

    <Countdown extdate="2023-05-08T23:59:59Z" class="countdown" />
    <ApplyButton id="apply-btn" />
  </div>
</template>

<script>
import ApplyButton from "../widgets/ApplyButton";
import Countdown from "../widgets/Countdown";
export default {
  components: {
    ApplyButton,
    Countdown,
  },
};
</script>

<style scoped lang="scss">
#apply-btn {
  position: absolute;
  bottom: 12vh;
  left: 30vw;
  z-index: 1;
  transform: scale(0.8);
}

.countdown {
  position: absolute;
  top: 50vh;
  left: 41vw;
  transform: scale(1.2);
}

#register-hero {
  height: 100vh;
  position: relative;
}

#background-mobile {
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.text-box-mobile {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 7.3em;
  margin-left: 1em;
}

.title {
  font-family: "Hind Siliguri";
  font-weight: bold;
  font-size: calc(24px + (36 - 24) * ((100vw - 300px) / (480 - 300)));
  color: $black;
  text-align: left;
  margin-bottom: -15px;
}

#six-mobile {
  font-family: "Rockwell";
  font-weight: bold;
  font-size: calc(124px + (136 - 124) * ((100vw - 300px) / (480 - 300)));
  color: $yellow;
  position: absolute;
  top: 0.5em;
  left: 43vw;
}

#six-desktop {
  display: none;
}

@media screen and (min-width: 480px) {
  #background-mobile {
    display: none;
  }

  #background-desktop {
    width: 100vw;
    height: 100vh;
  }

  .full-title-desktop {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 40%;
    top: 20%;
  }

  .text-box-mobile {
    display: none;
  }

  .title {
    color: $white;
    text-align: right;
  }

  #six-mobile {
    display: none;
  }

  #six-desktop {
    display: flex;
    font-family: "Rockwell";
    font-weight: bold;
    font-size: 226px;
    color: $yellow;
    position: relative;
    bottom: 30px;
  }

  #apply-btn {
    position: absolute;
    bottom: 20vh;
    left: 65vw;
    z-index: 1;
    transform: scale(1.5);
  }

  .countdown {
    position: absolute;
    bottom: -4vh;
    left: 33vw;
    transform: scale(2.4);
  }
}

@media screen and (max-width: 1366px) {
  #apply-btn {
    left: 50vw;
    bottom: 20vh;
  }
  .countdown {
    bottom: 35vh;
    left: 47vw;
    transform: scale(2);
  }
}

@media screen and (max-width: 480px) {
  #register-hero {
    background-color: $white;
  }

  .countdown {
    transform: scale(1.2);
  }

  #apply-btn {
    left: 35vw;
    bottom: 15vh;
  }

  #background-desktop {
    display: none;
  }

  .text-box-mobile {
    margin-left: 25px;
  }

  .title {
    margin-bottom: -16px;
  }

  #six-mobile {
    font-family: "Hind Siliguri";
    font-style: normal;
    font-weight: 600;
  }
}
</style>
