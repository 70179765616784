<template>
  <div id="countdown">
    <div class="text-box">
      <div class="countTitle">{{ $t("home.Countdown.header") }}</div>
    </div>
    <div class="timecase">
      <div class="block">
        <div class="case">
          <p class="digit">
            {{ days }}
          </p>
          <p class="text">d</p>
        </div>
      </div>
      <div class="block-middle">
        <div class="case">
          <p class="digit">
            {{ hours }}
          </p>
          <p class="text">h</p>
        </div>
      </div>
      <div class="block">
        <div class="case">
          <p class="digit">
            {{ minutes }}
          </p>
          <p class="text">m</p>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: ["extdate"],
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      date: Math.trunc(Date.parse(this.extdate) / 1000),
    };
  },
  computed: {
    seconds() {
      return (this.date - this.now) % 60 > 0 ? (this.date - this.now) % 60 : 0;
    },
    minutes() {
      return Math.trunc((this.date - this.now) / 60) % 60 > 0
        ? Math.trunc((this.date - this.now) / 60) % 60
        : 0;
    },
    hours() {
      return Math.trunc((this.date - this.now) / 60 / 60) % 24 > 0
        ? Math.trunc((this.date - this.now) / 60 / 60) % 24
        : 0;
    },
    days() {
      return Math.trunc((this.date - this.now) / 60 / 60 / 24)  - 1> 0
        ? Math.trunc((this.date - this.now) / 60 / 60 / 24)
        : 0;
    },
  },
  mounted() {
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
};
</script>
  
<style scoped lang="scss">
#countdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.case {
  display: flex;
}

.text-box {
  width: 100%;
}

.timecase {
  display: flex;
  justify-content: center;
  align-content: center;
}

.block,
.block-middle {
  display: flex;
  margin: 2%;
  margin-top: 3%;
  text-align: center;
}

.countTitle {
  font-size: $font_mobile_medium_1;
  color: $white;
  text-align: center;
  font-family: "PT Sans Caption";
  padding-top: 0.5 em;
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    width: 70%;
    height: 0.05em;
    background-color: $yellow;
    // margin: 0 80% 0 10%;
    left: -8px;
    bottom: 0;
  }
}

.text {
  color: $white;
  font-size: $font_mobile_medium_1;
  font-family: "PT Sans Caption";
  line-height: 1em;
  margin-bottom: -6%;
  margin-top: 6%;
  margin-left: 6%;
  font-weight: 300;
}

.digit {
  color: $white;
  font-size: $font_mobile_medium_1;
  margin-bottom: -6%;
  margin-top: 6%;
  font-weight: 300;
  font-family: "PT Sans Caption";
  line-height: 1em;
}

.block-middle::after,
.block-middle::before {
  content: "";
  color: $white;
  font-weight: bold;
  font-size: $font_mobile_medium_1;
  // line-height: 0.5em;
  // font-weight: 300;
  width: 2px;
  height: 80%;
  position: relative;
  top: 20%;
  background-color: $white;
}
.block-middle::after {
  margin: 0 0.3em 0 0.5em;
}

.block-middle::before {
  margin: 0 0.3em;
}

@media screen and (max-width: 340px) {
  .countTitle,
  .digit,
  .text {
    font-size: $font_mobile_small_3;
  }

  .block-middle::after,
  .block-middle::before {
    font-size: $font_mobile_small_3;
  }
}
</style>
  