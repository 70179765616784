<template>
  <div id="home-page">
    <HomeHero />
    <HomeCardGrid />
    <Countdown extdate="2023-05-08T23:59:59Z" class="countdown" />
    <Partners />
    <AboutEESTEC />
  </div>
</template>

<script>
import HomeHero from "../components/home-components/HomeHero";
import HomeCardGrid from "../components/home-components/HomeCardGrid";
import Partners from "../components/home-components/Partners";
import AboutEESTEC from "../components/home-components/AboutEESTEC";

export default {
  name: "Home",

  components: {
    HomeHero,
    HomeCardGrid,
    Partners,
    AboutEESTEC
  }
};
</script>

<style scoped lang="scss"></style>
