<template>
  <div id="devs-page">
    <div class="title-container">
      <h1>Designers</h1>
    </div>
    <div class="container">
      <div class="card" v-for="(person, index) in devs.architects" :key="index">
        <div class="img-container" @click="openQuote(index)">
          <img :src="person.photo" loading="lazy" />
          <div :class="{ quote: true, 'quote-open': quoteIsOpen[index] }">{{ person.quote }}</div>
        </div>
        <p class="name">{{ person.name }}</p>
      </div>
    </div>
    <div class="title-container">
      <h1>Developers</h1>
    </div>
    <div class="container">
      <div class="card" v-for="(person, index) in devs.templars" :key="index">
        <div class="img-container" @click="openQuote(index + 4)">
          <img :src="person.photo" loading="lazy" />
          <div :class="{ quote: true, 'quote-open': quoteIsOpen[index + 4] }">
            {{ person.quote }}
          </div>
        </div>
        <p class="name">{{ person.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import devs from "../assets/resources/devs";
export default {
  data() {
    return {
      devs,
      quoteIsOpen: new Array(20).fill(false)
    };
  },
  methods: {
    openQuote(index) {
      console.log(index);
      this.quoteIsOpen.splice(index, 1, !this.quoteIsOpen[index]);
    }
  },
  mounted() {
    const options = {
      root: null,
      threshold: 0,
      rootMargin: "0px"
    };
    // eslint-disable-next-line no-unused-vars
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }
        entry.target.classList.toggle("card-appear");
        observer.unobserve(entry.target);
        // eslint-disable-next-line no-undef
      }, options);
    });
    const cards = document.querySelectorAll(".card");
    cards.forEach(card => {
      observer.observe(card);
    });
  }
};
</script>

<style lang="scss" scoped>
#devs-page {
  padding-top: 10vh;
  background-color: $white;
}

.title-container:nth-of-type(3) {
  width: 100%;
  background-color: $blue;
  h1 {
    color: $white;
    &::after {
      background-color: $purple;
    }
  }
}

h1 {
  font-family: "PT Sans Caption";
  margin: auto;
  margin: 1em 2em 2em 2em;
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 80%;
    height: 2px;
    background-color: $yellow;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  &:nth-of-type(2) {
    background-color: $white;
    .name {
      color: $blue;
    }
  }

  &:nth-of-type(4) {
    padding-top: 35px;
    background-color: $blue;
    .name {
      color: $white;
    }
  }
}

.card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 60%;
  height: 370px;
  gap: 20px;
  transition: transform 500ms cubic-bezier(0.79, 1.85, 0.4, 0.62);
  &:first-of-type {
    .img-container::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 110%;
      left: 0;
      top: -5%;
      border: 4px solid $purple;
      transform: skew(7deg);
    }
    img {
      border-color: $yellow;
    }
    .name {
      text-align: center;
      margin: 0 0em;
      &::after {
        white-space: pre-wrap;
        content: " - Project Manager";
      }
    }
  }
  &:nth-child(odd) {
    transform: translateX(-120%);
  }

  &:nth-child(even) {
    transform: translateX(120%);
  }
  &-appear {
    width: 60%;
    transform: translateX(0) !important;
  }
}

.img-container {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 110%;
    left: 0;
    top: -5%;
    border: 4px solid $turquoise;
    transform: skew(-7deg);
    box-sizing: border-box;
  }
}

img {
  width: 205px;
  height: 280px;
  object-fit: cover;
  box-sizing: border-box;
  border: 3px solid $yellow;
}

.quote {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 40% 20px;
  color: transparent;
  transition: color 300ms ease, background-color 300ms ease;
  display: flex;
  font-family: "PT Sans Caption";
  &-open {
    background-color: rgba(46, 49, 49, 0.7);
    color: $white;
  }
}

.name {
  font-family: "Hind Siliguri";
  margin: 0.5em 2em 1em 2em;
  font-size: $font_mobile_medium_2;
}

@media screen and (min-width: 480px) {
  #devs-page {
    padding-top: 200px;
    background-color: $blue;
  }
  .title-container:nth-of-type(1) {
    background-color: $white;
  }
  .container {
    &:nth-of-type(2) {
      padding: 30px 0;
    }
  }
  .card {
    width: 300px;

    &:nth-child(odd) {
      transform: translateX(-100%);
    }

    &:nth-child(even) {
      transform: translateX(100%);
    }
    &-appear {
      transform: translateX(0) !important;
    }
    &:first-child {
      transform: translateX(-20%);
      width: 100%;
      & > .name {
        margin: 0 40vw;
      }
    }
  }

  .img-container:hover {
    cursor: pointer;
    & > .quote {
      background-color: rgba(46, 49, 49, 0.7);
      color: $white;
    }
  }
}
</style>
