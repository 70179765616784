<template>
  <div id="trainings">
    <img class="liniute" src="@/assets/images/svg1.svg" />
    <h1 class="fs-large-2">{{ $t("trainings.trainings.title") }}</h1>
    <div class="trainings-container">
      <div
        class="content"
        :class="{ 'content--extended': isExtended[index] }"
        v-for="(training, index) in $t('trainings.trainings.trainingsList')"
        :key="index"
      >
        <div class="training-card" :class="{ 'training-card--extended': isExtended[index] }">
          <p class="training-title fs-medium-1">{{ training.title }}</p>
          <p class="training-description fs-small-2 ">{{ training.description }}</p>
          <div class="footer fs-small-1" v-if="!isExtended[index]" @click="handleExtend(index)">
            {{ $t("trainings.trainings.readMore") }}
          </div>
          <div class="footer fs-small-1" v-if="isExtended[index]" @click="handleExtend(index)">
            {{ $t("trainings.trainings.readLess") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExtended: new Array(this.$t("trainings.trainings.trainingsList").length).fill(false)
    };
  },
  methods: {
    handleExtend(index) {
      this.isExtended.splice(index, 1, !this.isExtended[index]);
    }
  }
};
</script>

<style scoped lang="scss">
#trainings {
  background-color: $blue;
  width: 100%;
  padding-bottom: 30px;
  // height: 1530px;
  // margin-bottom: 300px;
}

.liniute {
  height: auto;
  width: 50%;
  margin-left: 50%;
}

h1 {
  font-size: $font_mobile_large_2;
  font-family: "Hind Siliguri";
  color: $white;
  margin-left: 1em;
  display: block;
  position: relative;
  text-align: left;
  margin-bottom: 39px;
  &::after {
    content: "";
    left: 0;
    background-color: $yellow;
    height: 0.07em;
    position: absolute;
    width: 2.6em;
    top: 1.3em;
  }
}

.trainings-container {
  margin: auto;
  display: grid;
  width: 90%;
  grid-template-columns: 100%;
  justify-items: center;
  row-gap: 3.35em;
  height: auto;
}

.content {
  box-sizing: border-box;
  // position: relative;
  height: 200px;
  background-color: $blue;
  justify-items: center;
  // margin-bottom: 53.64px !important;
}

.training-card {
  border-style: solid;
  border-width: 5px;
  border-image: linear-gradient(to right bottom, $yellow 50%, $turquoise 50%) 1;
  box-sizing: border-box;
  z-index: 2;
  // width: 21.31em;
  background-color: $white;
  height: 200px;
  display: flex;
  flex-direction: column;
  transition: height 300ms ease;
  @media screen and (max-width: 480px) {
    &--extended {
      // max-height: auto;
      height: 455px;
      transition: height 300ms ease;
      position: relative;
      z-index: 99;
      top: 0px;
      border-image: linear-gradient(to right bottom, $yellow 50%, $turquoise 50%) 1;

      .training-description {
        height: 500px;
        overflow: visible;
      }
    }
  }
}

.content:nth-last-child(1) {
  height: auto;
}

.training-title {
  font-family: "PT Sans Caption";
  position: relative;
  font-size: $font_mobile_medium_1;
  text-align: center;
  color: $black;
  margin-top: 10px;
  margin-bottom: 12.6px;
  padding: 0 5%;
}

.training-description {
  text-align: center;
  overflow: hidden;
  font-family: "MS-UI-Gothic";
  // font-size: $font_mobile_small_3;
  font-size: 17px;
  text-overflow: ellipsis;
  height: 50px;
  margin: auto;
  padding: 0 5%;
}

.footer {
  font-size: $font_mobile_small_1;
  font-family: "PT Sand Caption";
  text-align: right;
  padding: 1em 1em;
  align-content: flex-end;
}

@media screen and (min-width: 480px) {
  .trainings-container {
    grid-template-columns: repeat(auto-fit, 700px);
    gap: 100px;
    justify-content: center;
    transition: all 300ms ease;
  }



  .training-card {
    height: auto;
    max-height: 330px;
    transition: max-height 500ms ease;
  }

  .training-title {
    margin-top: 30px;
  }

  .training-description {
    transition: max-height 700ms ease;
    padding: 1em 1em;
    margin: 1em 0;
    height: auto;
    max-height: 100px;
    @media screen and (max-width: 1650px) {
      max-height: 60px;
    }
  }

  .content {
    justify-self: flex-start;
    height: auto;
    max-height: 300px;
    transition: max-height 500ms ease;
    &--extended {
      max-height: 1000px;
      z-index: 2;
      & > .training-card {
        max-height: 1000px;
        & > .training-description {
          max-height: 1000px;
        }
        z-index: 2;
      }
    }
  }

  h1 {
    margin-bottom: 2em;
    margin-left: 2em;
    &::after {
      width: 10%;
      height: 0.03em;
    }
  }
  .footer:hover {
    cursor: pointer;
  }
}
</style>
