import { render, staticRenderFns } from "./DevsPage.vue?vue&type=template&id=5414f943&scoped=true&"
import script from "./DevsPage.vue?vue&type=script&lang=js&"
export * from "./DevsPage.vue?vue&type=script&lang=js&"
import style0 from "./DevsPage.vue?vue&type=style&index=0&id=5414f943&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5414f943",
  null
  
)

export default component.exports