<template>
  <div id="apply-button" @click="toRegister">
    <p
      :class="{ 'fs-medium-1': true, 'is-white-button': $router.currentRoute.path == '/register' }"
    >
      {{ $t(`global.button-apply`) }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colors: "black"
    };
  },
  methods: {
    toRegister() {
      if (this.$router.currentRoute.path == "/register") {
        window.location.href = "https://forms.gle/7o6aucVw7aaq2Lko6";
      } else {
        this.$router.push("/register");
      }
    }
  }
  // created() {
  //   if (this.$router.currentRoute.path == "/register") this.colors = "$white";
  //   else this.colors = "$black";
  // },
  // watch: {
  //   $route() {
  //     if (this.$router.currentRoute.path == "/register") this.colors = "$white";
  //     else this.colors = "$black";
  //   },
  // },
};
</script>

<style scoped lang="scss">
#apply-button {
  // transform: scale(0.7);
  max-height: 300px;
  max-width: 400px;
  &:hover {
    cursor: pointer;
  }
}
p {
  position: relative;
  font-family: "Hind Siliguri";
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em 0.5em;

  &::before {
    content: "";
    position: absolute;
    width: 130%;
    height: 100%;
    border: 0.12em solid $yellow;
    transform: skew(-40deg);
    transition: 0.3s ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    width: 150%;
    height: 120%;
    border: 0.12em solid $turquoise;
    transform: skew(-40deg);
    transition: 0.3s ease-in-out;
  }
}

p:hover {
  cursor: pointer;
  &::before {
    width: 150%;
    height: 120%;
  }
  &::after {
    width: 130%;
    height: 100%;
  }
}
@media screen and (min-width: 480px) {
  p {
    padding-right: 1em !important;
    &::before {
      border: 0.08em solid $yellow;
    }

    &::after {
      border: 0.08em solid $turquoise;
    }
  }
}

.is-white-button {
  color: $white;
}
</style>
