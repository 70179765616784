<template>
  <div id="contact-hero">
    <div class="triangle"></div>
    <h1 class="fs-large-2">{{ $t("contact.contactHero.location") }}</h1>
    <div class="container">
      <div class="map">
        <gmap-map :center="center" :zoom="16.5" class="google-map box">
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center = m.position"
          />
        </gmap-map>
      </div>
      <div class="text-mobile">
        <p class="lc-name" v-html="$t('contact.contactHero.lc_title') + '<br>'"></p>
        <p class="address" v-html="$t('contact.contactHero.address') + '<br>'"></p>
        <div>
          <p class="phone ">{{ $t("contact.contactHero.phone") }}</p>
          <p class="email ">{{ $t("contact.contactHero.email") }}</p>
        </div>
      </div>
      <div class="text-desktop">
        <p class="lc-name-desktop fs-medium-5">{{ $t("contact.contactHero.lc_title_desktop") }}</p>
        <p class="address-desktop fs-medium-2">
          {{ $t("contact.contactHero.address_desktop") }}
        </p>
        <pre class="phone-email-desktop fs-medium-2"
          >{{ $t("contact.contactHero.phone") }} | {{ $t("contact.contactHero.email") }}</pre
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      center: { lat: 44.43872, lng: 26.05015 },
      markers: [
        {
          position: { lat: 44.438743, lng: 26.050059 }
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
#contact-hero {
  padding: 40px;
  background-color: $blue;
  position: relativ;
  padding-bottom: 80px;
  min-height: 100vh;
}
.text-mobile {
  display: flex;
  flex-direction: column;
  row-gap: 2em;
}
.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 60vh solid $white;
  border-right: 75vw solid transparent;
  top: 0px;
  left: 0px;
}
h1 {
  color: $black;
  text-align: left;
  position: relative;
  font-family: "Hind Siliguri";
  font-size: $font_mobile_large_2;
  font-weight: 700;
  padding-top: 70px;
  margin-bottom: 30px;
  &::after {
    content: "";
    position: absolute;
    width: 21%;
    height: 0.05em;
    background-color: $purple;
    bottom: -0.1em;
    left: 0%;
  }
}
.container {
  display: flex;
  flex-direction: column;
  row-gap: 4em;
  align-items: center;
  margin: auto;
  z-index: 1;
  position: relative;
  @media screen and (max-height: 680px) {
    row-gap: 1.8em;
  }
}

.map {
  width: 18em;
  height: 12em;
  position: relative;
  z-index: 101;
  animation: 0.5s ease-out 0.5s appear;
  border-top: 6px solid $turquoise;
  border-left: 6px solid $turquoise;
  border-right: 6px solid $yellow;
  border-bottom: 6px solid $yellow;
  @media screen and (max-width: 375px) {
    width: 15em;
    height: 11em;
  }
}
.google-map {
  z-index: 105 !important;
  height: 100%;
  width: 100%;
}

.vue-map {
  margin-bottom: 0px !important;
}
p {
  color: white;
}

.lc-name {
  font-family: "Hind Siliguri";
  font-weight: 700;
  font-size: $font-mobile_large_1;
  text-align: center;
  @media screen and (max-width: 375px) {
    font-size: $font-mobile_medium_2;
  }
}

.phone {
  font-family: "PT Sans Caption";
  font-size: $font_mobile_medium_1;
  @media screen and (max-width: 375px) {
    font-size: $font-mobile_small_3;
  }
}
.email {
  font-family: "PT Sans Caption";
  font-size: $font_mobile_medium_1;
  @media screen and (max-width: 375px) {
    font-size: $font-mobile_small_3;
  }
}
.address {
  font-family: "PT Sans Caption";
  font-size: $font_mobile_medium_1;
  @media screen and (max-width: 375px) {
    font-size: $font-mobile_small_3;
  }
}
@media screen and (max-width: $min_desktop_width) {
  .text-desktop {
    display: none;
  }
}
@media screen and (min-width: $min_desktop_width) {
  #contact-hero {
    // height: 100vh;
    width: 100%;
    padding: 5em 5em 10em 3em;
    background-color: $blue;
    // max-height: 56.25vw;
  }
  .text-mobile {
    display: none;
  }
  .text-desktop {
    display: flex;
    flex-direction: column;
    // row-gap: 2em;
    margin: auto;
    text-align: right;
    row-gap: 4em;
  }
  .phone-email-desktop {
    color: white;
    font-family: "PT Sans Caption";
    font-weight: 300;
  }
  .lc-name-desktop {
    font-family: "Hind Siliguri";
    font-weight: 700;
  }
  .address-desktop {
    font-family: "PT Sans Caption";
    font-weight: 300;
  }
  .lc-name-desktop::before {
    content: " ";
    float: left;
    width: 75%;
    height: 1em;
    shape-outside: content-box polygon(0 0, 100% 0, 61% 39%, 52.6% 47.1%, 0 100%);
  }
  .address-desktop::before {
    content: " ";
    float: left;
    width: 40%;
    height: 2.5em;
    shape-outside: content-box polygon(0 0, 100% 0, 0 99.2%);
  }
  .triangle {
  border-top: 60vh solid $white;
  border-right: 50vw solid transparent;
  }
  h1 {
    width: 5em;
    position: relative;
    margin-left: 2em;
    &::after {
      content: "";
      position: absolute;
      width: 2em;
      height: 0.05em;
      background-color: $purple;
      bottom: 0.2em;
      left: 0%;
    }
  }
  .map {
    width: 100%;
    height: 500px;
    position: relative;
    z-index: 2;
  }
  .container {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 5em;
    align-items: flex-start;
    margin: auto;
    z-index: 1;
    position: relative;
    margin-top: 10vh;
  }
  @media screen and (max-width: 900px) {
    #contact-hero {
    // height: 100vh;
    width: 100%;
    padding: 5em 2em 10em 0em;
    }
    .container {
       flex-direction: column-reverse;
    }
    .map {
       width: 70%;
      height: 20vh;
      margin: auto;
    }
    .text-desktop {
      row-gap: 2em;
    }
  }
} 
</style>
