<template>
  <div id="trainings-page">
    <TrainingsHero />
    <Trainings />
    <!-- <Trainers />
    <CaseStudy /> -->
  </div>
</template>

<script>
import TrainingsHero from "../components/trainings-components/TrainingsHero";
import Trainings from "../components/trainings-components/Trainings";
// import Trainers from "../components/trainings-components/Trainers";
// import CaseStudy from "../components/trainings-components/CaseStudy";

export default {
  components: {
    TrainingsHero,
    Trainings,
    // CaseStudy,
    // Trainers
  },
};
</script>

<style scoped lang="scss">
</style>
